import gql from "graphql-tag";

export const getQuotesRecent = gql`
    query GetQuotesRecent($allUsers: Boolean) {
        getQuotesRecent(allUsers: $allUsers) {
            id
            user {
                name
            }
            filename
            customer {
                name
                city
                state
            }
            createdTime
        }
    }
`;

export const quotesSubscription = gql`
  subscription onAddedQuote($userId: ID!) {
      addedQuote(userId: $userId) {
          id
          user {
              name
          }
          userId
          filename
          customer {
              name
              city
              state
          }
          createdTime
      }
  }
`

export const getQuoteQuery = gql`
    query GetQuote($quoteId: ID!) {
        getQuote(quoteId: $quoteId) {
            id
            customer {
                name
                address
                city
                state
                zip
                phone
                email
            }
            createdTime
            contractConfig {
                additionalFiles {
                    s3Path
                    name
                    mimeType
                }
                templateName
                additionalTabs {
                    tabLabel
                    value
                }
                isDraft
                isInPersonSigning
                notes {
                    top
                    bottom
                    rooms {
                        roomName
                        roomNumber
                        note
                    }
                }
                discount {
                    percentDescription
                    percent
                    flatDescription
                    flat
                }
            }
        }
    }
`;

export const getQuoteItemsQuery = gql`
    query GetQuoteItems($quoteId: ID!) {
        getQuoteItems(quoteId: $quoteId) {
            __typename
            id
            quantity
            name
            importSkip
            price
            isAccessory
            attributes {
                __typename
                id
                key
                name
                valueDisplay
                ... on QuoteItemAttributeString {
                    __typename
                    valueString
                }
                ... on QuoteItemAttributeNumber {
                    __typename
                    valueNumber
                }
            }
            part {
                category {
                    id
                    name
                }
                id
                name
                vendorSkus {
                    id
                    vendor {
                        id
                        name
                    }
                    sku
                    manufactured
                }
            }
            location {
                roomNumber
                unitNumber
                roomName
            }
        }
    }
`;

export const addQuoteItemMutation = gql`
    mutation AddQuoteItem($quoteId:ID!, $quoteItem:QuoteItemInput!) {
        addQuoteItem(quoteId:$quoteId, quoteItem:$quoteItem) {
            __typename
            id
            name
            price
            isAccessory
            location {
                roomNumber
                unitNumber
                roomName
            }
        }
    }
`;

export const setQuoteItemPartMutation = gql`
    mutation SetQuoteItemPart($quoteId: ID!, $itemId: ID!, $partId: ID!) {
        setQuoteItemPart(quoteId:$quoteId, itemId:$itemId, partId:$partId) {
            __typename
            id
            part {
                category {
                    id
                    name
                }
                id
            }
        }
    }
`;

export const setQuoteContractConfig = gql`
    mutation SetQuoteContractConfig($quoteId:ID!, $config:QuoteContractConfigInput!) {
        setQuoteContractConfig(quoteId:$quoteId, config:$config) {
            id
            contractConfig {
                additionalFiles {
                    s3Path
                    name
                    mimeType
                }
                templateName
                additionalTabs {
                    tabLabel
                    value
                }
                isDraft
                isInPersonSigning
                notes {
                    top
                    bottom
                    rooms {
                        roomName
                        roomNumber
                        note
                    }
                }
                discount {
                    percentDescription
                    percent
                    flatDescription
                    flat
                }
            }
        }
    }
`;
export const sendQuoteContractMutation = gql`
    mutation SendQuoteContract($quoteId:ID!, $attachmentContent:String!) {
        sendQuoteContract(quoteId:$quoteId, attachmentContent:$attachmentContent) {
            envelopeId
            draftUrl
            inPersonSigningUrl
        }
    }
`;

export const getDocusignConfigQuery = gql`
    query GetDocusignConfig {
        getDocusignConfig {
            clientId
            environment
            templates {
                templateName
                templateId
                additionalTabs {
                    tabLabel
                    displayName
                }
            }
            defaultTabs {
                tabLabel
                sampleValue
            }
        }
    }
`;

export const setQuoteItemsAttributeValueOverrideMutation = gql`
    mutation SetQuoteItemsAttributeValueOverride ($quoteId:ID!, $itemIds:[ID!]!, $attributeKey:String!, $valueOverride:String!) {
        setQuoteItemsAttributeValueOverride(quoteId:$quoteId, itemIds:$itemIds, attributeKey:$attributeKey, valueOverride:$valueOverride) {
            __typename
            id
            key
            name
            valueDisplay
            ... on QuoteItemAttributeString {
                __typename
                valueString
            }
            ... on QuoteItemAttributeNumber {
                __typename
                valueNumber
            }
        }
    }
`;

export const setQuoteItemNameOverride = gql`
    mutation SetQuoteItemNameOverride($itemId:ID!, $nameOverride:String!) {
        setQuoteItemOverride(itemId:$itemId, nameOverride:$nameOverride) {
            __typename
            id
            name
        }
    }
`;

export const setQuoteItemPriceOverride = gql`
    mutation SetQuoteItemPriceOverride($itemId:ID!, $priceOverride:Float!) {
        setQuoteItemOverride(itemId:$itemId, priceOverride:$priceOverride) {
            __typename
            id
            price
        }
    }
`;


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient } from '@apollo/client/core';
import {ApolloProvider} from '@apollo/client'
import Amplify from "aws-amplify";
import { InMemoryCache } from '@apollo/client/cache';
import { Auth } from "aws-amplify";

import { createHttpLink } from 'apollo-link-http';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloLink } from 'apollo-link';
import { setContext } from "apollo-link-context";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "us-west-2",
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
  Storage: {
    region: "us-west-2",
    bucket: process.env.REACT_APP_QUOTE_CONTRACT_ATTACHMENTS_BUCKET,
  }
});

let auth = {
  type: "AWS_IAM",
  credentials: () => Auth.currentCredentials(),
};

const gqlUrl = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const region = "us-west-2";

const httpLink = createHttpLink({ uri: gqlUrl });

const verifyCognitoCredsLink = setContext(() => {
  return Auth.currentCredentials()
      .catch(e => {
        console.error(e);
        throw new Error("Not logged in");
      })
});

const userIdMiddleware = setContext(operation => {
  return Auth.currentCredentials().then(creds => {
    let userId = null;
    if (creds || creds.authenticated) {
      if (creds || creds.authenticated) {
        userId = creds.data.IdentityId;
      }
    }

    if (userId) {
      return {
        // Make sure to actually set the headers here
        headers: {
          "x-userid": userId,
        },
      };
    }
    return {};
  });
});


const link = ApolloLink.from([
  verifyCognitoCredsLink,
  userIdMiddleware,
  createAuthLink({ url: gqlUrl, region, auth }),
  createSubscriptionHandshakeLink(gqlUrl, httpLink),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    possibleTypes: {
      QuoteItemAttribute: ["QuoteItemAttributeString", "QuoteItemAttributeNumber"],
    },
    typePolicies: {
      Quote: {
        fields: {
          customer: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
  }),
});

ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>,
    document.getElementById("root")
);

